
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
@import "./utils/variables/_typography";
@import "./utils/components/_gallerySlide";
@import "./utils/variables/_button";
@import "./utils/components/_bannerSlide";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
	list-style: none;
	font-family: Lato;
	font-size: 16px;
	line-height: normal;
	color: $raisin-black;
	font-weight: 300;
	box-sizing: border-box;

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $ultramarine-blue-75-opacity;
		height: 40%;
	}
}

body {
	background-color: $cultured;
}

.wpp-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 2;
}

.CMS {
	color: $eerie-black;
	text-align: center;
	font-family: "CMS" !important;
	font-size: 32px;
	text-transform: uppercase;
	font-weight: 400;

	transform: rotate(0deg);
	transition: all 0.3s ease;

	&.rotate {
		transform: rotate(180deg);
	}
}

.no-select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 600;
	-webkit-tap-highlight-color: $ultramarine-blue-17-opacity;
}

a {
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 700;
	-webkit-tap-highlight-color: $ultramarine-blue-17-opacity;
}

p {
	font-size: 20px;
	line-height: 150%;
}

h1 {
	font-size: 32px;
	font-weight: 800;
	text-transform: uppercase;

	@media (min-width: 1140px) {
		font-size: 56px;
	}
}

.no-scroll {
	overflow: hidden;
}

main {
	display: flex;
	flex-direction: column;

	& > *:not(:first-child):not(:nth-child(2)) {
		margin-top: 48px;
	}

	@media (min-width: 1140px) {
		& > *:not(:first-child):not(:nth-child(2)) {
			margin-top: 20px;
		}
	}
}

.defesas {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	gap: 64px;

	> p {
		font-size: 16px;

		b,
		i {
			font-size: 16px;
		}
	}
}

body.dark {
	background-color: $eerie-black;

	&::-webkit-scrollbar-thumb {
		background-color: $golden-yellow;
	}

	* {
		color: $cultured;

		&::-webkit-scrollbar-thumb {
			background-color: $golden-yellow;
		}
	}

	p,
	span {
		font-weight: 400;
	}

	button,
	a {
		-webkit-tap-highlight-color: $golden-yellow-17-opacity;
	}

	.leaflet-control-zoom-in,
	.leaflet-control-zoom-out {
		> span {
			color: $black;
			font-weight: 600;
			font-size: 20px;
		}
	}
}
